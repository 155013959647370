import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import ArrowButton from '../../components/Common/ArrowButton'
import ModalIframe from '../../components/Common/ModalIframe'

import YoutubePlayIcon from '../../svg/youtube-play-icon'

import './NovemberDigest2024.styles.scss'
import * as s from '../../pages/news.module.scss'
import ModalImage from '../../components/Common/ModalImage'
import { Button } from '../../components/Common/Button'
import { useTranslations } from '../../hooks/use-translations'

const NovemberDigest2024 = () => {
  const { getPath } = useTranslations()
  const [modalVideoData, setModalVideoData] = React.useState<{
    title: string
    src: string
  }>()
  const [imageData, setImageData] = React.useState<{
    title: string
    image: string
  }>()
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const activitiesData = [
    {
      imageId: 'image1',
      alt: 'Activities-photo-1',
    },
    {
      imageId: 'image2',
      alt: 'Activities-photo-2',
    },
    {
      imageId: 'image3',
      alt: 'Activities-photo-3',
    },
    {
      imageId: 'image4',
      alt: 'Activities-photo-4',
    },
    {
      imageId: 'image5',
      alt: 'Activities-photo-5',
    },
  ]
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Digest of the November updates on SIGNAX products"
        description="SIGNAX November 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation."
      />
      <NewsSectionBlock date="December 6, 2024">
        <Typography variant="h1" color="blue">
          November Digest SIGNAX
        </Typography>
        <Typography variant="h3">General:</Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>This November</span>, SIGNAX brought
          exciting product updates and new media content. At the end of the
          month, our team attended{' '}
          <span style={{ fontWeight: 500 }}>The Big 5 2024 in Dubai</span>,
          connecting with industry leaders and exploring innovations of
          construction.
        </Typography>
        <Typography variant="body1">
          We also published an{' '}
          <span style={{ fontWeight: 500 }}>insightful article</span> on the
          digitalization of construction through{' '}
          <span style={{ fontWeight: 500 }}>CDE systems</span>, highlighting
          their transformative role in modern{' '}
          <span style={{ fontWeight: 500 }}>project management</span>. Click the
          button below to dive into the full article:
        </Typography>
        <Button
          className="mx-auto"
          size="small"
          onClick={() =>
            navigate(
              getPath('/cde-first-step-toward-construction-digitalization/')
            )
          }
        >
          Open Article
        </Button>
        <Typography variant="body1">
          Additionally, we released an implementation-focused video showcasing
          how <span style={{ fontWeight: 500 }}>SIGNAX</span> products empower{' '}
          <span style={{ fontWeight: 500 }}>general contractors’</span>{' '}
          processes with{' '}
          <span style={{ fontWeight: 500 }}>digital solutions</span>. Watch it
          in the player below:
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: `SIGNAX. General contractors' processes`,
              src: 'https://youtube.com/embed/WjHAqRHpOyA?si=Vvi08lpn4h5enfLn',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview1.childImageSharp.gatsbyImageData}
            alt="SIGNAX. General contractors' processes"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3">DOCS:</Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>SIGNAX DOCS</span> received several
          updates to make data management and collaboration smoother and more
          efficient. We introduced new{' '}
          <span style={{ fontWeight: 500 }}>approval statuses</span> for
          document sets, making it easier to track progress at a glance. With{' '}
          <span style={{ fontWeight: 500 }}>enhanced attribute management</span>
          , users can assign detailed information to files, folders, and issues
          while editing attributes directly in their registries.
        </Typography>
        <Typography variant="body1">
          Our{' '}
          <span style={{ fontWeight: 500 }}>
            Approval Calendar and Workflow Schemes
          </span>{' '}
          have been upgraded, adding tooltips that provide more context on who
          initiated approvals and when. Finally,{' '}
          <span style={{ fontWeight: 500 }}>markups display unique IDs</span>,
          improving traceability and project clarity.{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX DOCS</span> continues to
          enhance how teams collaborate and{' '}
          <span style={{ fontWeight: 500 }}>manage critical project data</span>.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: `#DevNews. SIGNAX DOCS. Schedules, Reviews and Notifications`,
              src: 'https://youtube.com/embed/VW8DM-Ouz2s',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview2.childImageSharp.gatsbyImageData}
            alt="#DevNews. SIGNAX DOCS. Schedules, Reviews and Notifications"
          />
          <YoutubePlayIcon />
        </div>

        <Typography variant="h3">TOOLS:</Typography>
        <Typography variant="body1">
          This month’s focus for{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX TOOLS</span> was on practical
          use cases, highlighting how the module helps teams optimize workflows
          and maximize efficiency.{' '}
          <span style={{ fontWeight: 500 }}>Scopes extraction features</span>{' '}
          allow users to calculate precise quantities from BIM models in
          seconds. By creating customizable quantity properties, professionals
          can ensure their models align perfectly with project needs.{' '}
          <span style={{ fontWeight: 500 }}>The BOQ export</span> functionality
          enables users to generate work scopes and track progress for{' '}
          <span style={{ fontWeight: 500 }}>WBS packages</span> directly in
          their models.
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>
            Project invoicing is now seamless
          </span>{' '}
          with tools that create detailed, custom invoices, including model
          visuals, saving time and ensuring professionalism. For{' '}
          <span style={{ fontWeight: 500 }}>model validation</span>, reusable
          templates and clash matrices streamline the QA/QC process, while
          <span style={{ fontWeight: 500 }}>
            scheduled autoprocessing tasks
          </span>{' '}
          eliminate minimise work. With these solutions,{' '}
          <span style={{ fontWeight: 500 }}>
            SIGNAX TOOLS empowers BIM managers to work faster and deliver better
            results.
          </span>
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX TOOLS. Quantity Takeoff',
              src: 'https://youtube.com/embed/W-NPNGaYgT4?si=GJPDFIPM-S92S4d6',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview3.childImageSharp.gatsbyImageData}
            alt="SIGNAX TOOLS. Quantity Takeoff"
          />
          <YoutubePlayIcon />
        </div>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: 'SIGNAX TOOLS. Model checker',
              src: 'https://youtube.com/embed/QFCFPDGs3sg?si=gkE6o43SgIXcIHHs',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview4.childImageSharp.gatsbyImageData}
            alt="SIGNAX TOOLS. Model checker"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3">INSPECTION:</Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>INSPECTION</span> will reach new
          heights with the integration of{' '}
          <span style={{ fontWeight: 500 }}>AI-powered tools</span>. It will
          process <span style={{ fontWeight: 500 }}>360° site photos</span>,
          identifying completed works, equipment, and on-site violations. This
          cutting-edge technology automates{' '}
          <span style={{ fontWeight: 500 }}>report generation</span> and flags
          action items, making inspections faster and more effective.
        </Typography>
        <Typography variant="body1">
          For QA/QC workflows, SIGNAX is actively developing a new module -{' '}
          <span style={{ fontWeight: 500 }}>BUILD</span> that will provide
          customizable checklists, deviation tracking, and NCR management, all
          integrated with <span style={{ fontWeight: 500 }}>360° visuals</span>{' '}
          for added clarity. From creating contractor tasks to generating
          inspection reports,{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX&nbsp;BUILD</span> ensures
          that the quality{' '}
          <span style={{ fontWeight: 500 }}>
            of your project is under control.
          </span>
        </Typography>
        <Typography variant="h3">DASHBOARD:</Typography>
        <Typography variant="body1">
          The <span style={{ fontWeight: 500 }}>SIGNAX DASHBOARD</span> added
          new tools for tracking and analyzing project data in real-time. The
          latest <span style={{ fontWeight: 500 }}>analytics cards</span> offer
          detailed insights into{' '}
          <span style={{ fontWeight: 500 }}>review statistics</span>, helping
          teams monitor open, in-progress, and completed reviews.{' '}
          <span style={{ fontWeight: 500 }}>Review dynamics</span> provide
          trends over time, while a{' '}
          <span style={{ fontWeight: 500 }}>review registry</span> highlights
          overdue and ahead-of-schedule approvals.
        </Typography>
        <Typography variant="body1">
          These updates ensure all project stakeholders stay informed. With
          these enhancements,{' '}
          <span style={{ fontWeight: 500 }}>SIGNAX DASHBOARD</span> becomes an
          indispensable solution for clear{' '}
          <span style={{ fontWeight: 500 }}>project insights</span> and making{' '}
          <span style={{ fontWeight: 500 }}>data-driven decisions.</span>
        </Typography>
        <Typography variant="h3">ACTIVITIES:</Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 500 }}>Big 5 Dubai 2024</span> was a
          standout event in November, where our team met industry leaders,
          exchanged insights, and shared how{' '}
          <span style={{ fontWeight: 500 }}>
            SIGNAX solutions empower the construction industry.
          </span>{' '}
          The event also underscored the importance of integrating digital
          solutions into modern construction workflows.
        </Typography>
        <div className="flex items-center">
          <ArrowButton
            onClick={() => swiperRef.current?.swiper.slidePrev()}
            disabled={disabledNavigationPrev}
            arrowType="left"
            className="november-digest_btn"
          />
          <Swiper
            ref={swiperRef}
            freeMode={true}
            grabCursor={true}
            navigation={true}
            autoHeight={true}
            onSlideChange={swiper => {
              setDisabledNavigationPrev(swiper.isBeginning)
              setDisabledNavigationNext(swiper.isEnd)
            }}
            pagination={{
              clickable: true,
              bulletClass: 'november-digest_pagination',
              bulletActiveClass: 'active',
            }}
            modules={[Pagination]}
          >
            {activitiesData.map((item, idx) => (
              <SwiperSlide key={idx}>
                <div
                  className="flex flex-col items-center justify-center  mb-6"
                  onClick={() =>
                    setImageData({
                      title: item.alt,
                      image: item.imageId,
                    })
                  }
                >
                  <GatsbyImage
                    image={
                      images[item.imageId + `Preview`].childImageSharp
                        .gatsbyImageData
                    }
                    alt={item.alt}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowButton
            arrowType="right"
            onClick={() => swiperRef.current?.swiper.slideNext()}
            disabled={disabledNavigationNext}
            className="november-digest_btn"
          />
        </div>
      </NewsSectionBlock>
      {modalVideoData && (
        <ModalIframe
          title={modalVideoData.title}
          src={modalVideoData.src}
          srcTitle={modalVideoData.title}
          onClose={() => setModalVideoData(undefined)}
        />
      )}
      {imageData && (
        <ModalImage
          title={imageData.title}
          onClose={() => setImageData(undefined)}
        >
          <GatsbyImage
            image={images[imageData.image].childImageSharp.gatsbyImageData}
            alt={imageData.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              maxHeight: 'calc(100vh - 100px)',
            }}
          />
        </ModalImage>
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    videoPreview1: file(
      relativePath: { eq: "news/november-digest-2024/video-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview2: file(
      relativePath: { eq: "news/november-digest-2024/video-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview3: file(
      relativePath: { eq: "news/november-digest-2024/video-preview-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview4: file(
      relativePath: { eq: "news/november-digest-2024/video-preview-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image1: file(
      relativePath: { eq: "news/november-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image1Preview: file(
      relativePath: { eq: "news/november-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 680, placeholder: BLURRED)
      }
    }
    image2: file(
      relativePath: { eq: "news/november-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image2Preview: file(
      relativePath: { eq: "news/november-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 680, placeholder: BLURRED)
      }
    }
    image3: file(
      relativePath: { eq: "news/november-digest-2024/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image3Preview: file(
      relativePath: { eq: "news/november-digest-2024/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 680, placeholder: BLURRED)
      }
    }
    image4: file(
      relativePath: { eq: "news/november-digest-2024/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image4Preview: file(
      relativePath: { eq: "news/november-digest-2024/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image5: file(
      relativePath: { eq: "news/november-digest-2024/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image5Preview: file(
      relativePath: { eq: "news/november-digest-2024/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default NovemberDigest2024
